import { ICompletionReportModule, IFeedbackReport } from "@/types/assessment";
import { NewSavedSearchType, SavedSearchType } from "@/types/candidate";
import { TRelevantJob } from "@/types/job";
import { TagType } from "@/types/tag";
import {
  JobRequirementSubmissionNameType,
  PaginatedResponse,
} from "@/types/utils";

import axiosInstance from "@/services/api/axiosInstance";

const user = "user";
const candidateScreeningQuestions = `candidate-screening-questions`;

const tags = "tags";
const candidateTags = "candidate-tags";

// bulk actions
const candidateRemindersBulkStatusUpdate = `candidate-reminders-bulk-status-update`;
const candidateMessageBulkStatusUpdate = `candidate-message-bulk-status-update`;

const candidateBulkUpdate = `candidate-bulk-update`;
const candidateTagBulkStatusUpdate = `candidate-tag-bulk-status-update`;
const candidateRemoveFromList = `candidate-remove-from-list`;
const jobApplicationStatuses = `job-application-statuses`;

const addInterviewerFromCandidate = `add-interviewer-from-candidate`;

const latestCandidateNotes = `latest-candidate-notes`;
const rejectCandidate = `reject-candidate`;

//save search
const userSearchFilters = `user-search-filters`;

const candidateRejectionReasons = `candidate-rejection-reasons`;
const candidateDropReasons = `candidate-drop-reasons`;

const candidatesRelevantJobs = `candidate-match-job-requirements`;

const candidateJobMatchReport = `candidate-job-match-report`;

// interview report
const interviewReport = `interview-completion-report`;
// feedback report
const feedbackReport = `interview-transcript-view`;

export default {
  getRecruiters() {
    return axiosInstance.get(`${user}/`);
  },

  getScreeningQuestions(params?: { search?: string }) {
    return axiosInstance.get(`${candidateScreeningQuestions}/`, { params });
  },

  getTags(params: { page?: number; search: string }) {
    return axiosInstance.get<PaginatedResponse<TagType>>(`${tags}/`, {
      params,
    });
  },

  getCandidateRelevantJobs(candidate_id: number) {
    return axiosInstance.get<TRelevantJob[]>(
      `${candidatesRelevantJobs}/?candidate=${candidate_id}`,
    );
  },
  postTag(data: { name: string }) {
    return axiosInstance.post(`${tags}/`, data);
  },

  patchTag(tagId: number, data: { name: string }) {
    return axiosInstance.patch(`${tags}/${tagId}/`, data);
  },
  deleteTag(tagId: number) {
    return axiosInstance.delete(`${tags}/${tagId}/`);
  },

  //
  postCandidateTag(data: { tag: number; candidate: number }) {
    return axiosInstance.post(`${candidateTags}/`, data);
  },

  deleteCandidateTag(tagId: number) {
    return axiosInstance.delete(`${candidateTags}/${tagId}/`);
  },

  //
  postBulkReminders(data: {
    reminder: string;
    candidate_ids: number[];
    reminder_datetime: string;
  }) {
    return axiosInstance.post(`${candidateRemindersBulkStatusUpdate}/`, data);
  },

  postBulkMail(data: {
    candidate_ids: number[];
    template_id: number;
    email_send: boolean;
    whatsapp_send: boolean;
    job_requirement_ids?: number[];
  }) {
    return axiosInstance.post(`${candidateMessageBulkStatusUpdate}/`, data);
  },

  postBulkTag(data: { candidate_ids: number[]; tag_ids: number[] }) {
    return axiosInstance.post(`${candidateTagBulkStatusUpdate}`, data);
  },

  deleteBulkTag(data: { candidate_ids: number[]; tag_ids: number[] }) {
    return axiosInstance.delete(`${candidateTagBulkStatusUpdate}`, {
      data,
    });
  },

  patchBulkCandidateStageStatus(data: {
    candidate_ids: number[];
    job_id: number;
    stage: number;
    interview_round?: number;
  }) {
    return axiosInstance.post(`${candidateBulkUpdate}/`, data);
  },

  deleteBulkCandidateFromUnderscreeningStatus(data: {
    candidate_ids: number[];
    job_ids: number[];
  }) {
    return axiosInstance.delete(`${candidateRemoveFromList}`, { data });
  },

  getGlobalJobApplicationStatuses() {
    return axiosInstance.get<
      { id: number; name: JobRequirementSubmissionNameType }[]
    >(`${jobApplicationStatuses}/`);
  },

  getLatestCandidateNotes(data: { candidate_ids: number[] }) {
    return axiosInstance.post<
      Record<string, { note: string; edited: boolean }>
    >(`${latestCandidateNotes}`, data);
  },

  postRejectCandidateForJob(data: {
    other_reason: string;
    reasons: number[];
    job_id: number;
    candidate_id: number;
  }) {
    return axiosInstance.post(`${rejectCandidate}`, data);
  },

  postAddInterviewerFromCandidate(data: {
    candidate_id: number;
    skills: number[];
    levels: number[];
  }) {
    return axiosInstance.post(`${addInterviewerFromCandidate}`, data);
  },

  getSavedSearches(params?: { search?: string; page?: number }) {
    return axiosInstance.get<PaginatedResponse<SavedSearchType>>(
      `${userSearchFilters}`,
      {
        params,
      },
    );
  },

  getSavedSearch(id: number) {
    return axiosInstance.get<SavedSearchType>(`${userSearchFilters}/${id}/`);
  },

  postSavedSearch(data: NewSavedSearchType) {
    return axiosInstance.post<SavedSearchType>(`${userSearchFilters}`, data);
  },

  patchSavedSearch(data: SavedSearchType) {
    return axiosInstance.patch(`${userSearchFilters}`, data);
  },

  deleteSavedSearch(data: SavedSearchType) {
    return axiosInstance.delete(`${userSearchFilters}`, { data });
  },

  getCandidateRejectionReasons() {
    return axiosInstance.get<{ reason: string; id: number }[]>(
      `${candidateRejectionReasons}`,
    );
  },

  getCandidateDropReasons() {
    return axiosInstance.get<{ reason: string; id: number }[]>(
      `${candidateDropReasons}`,
    );
  },

  getInterviewReport(id: number) {
    return axiosInstance.get<{ data?: ICompletionReportModule[] }>(
      `${interviewReport}/${id}`,
    );
  },

  getTranscriptView(id: number) {
    return axiosInstance.get<IFeedbackReport>(
      `${feedbackReport}/?interview=${id}`,
    );
  },

  postCandidateJobMatchReport(params: { candidate: number; job: number }) {
    return axiosInstance.post(`${candidateJobMatchReport}/`, params);
  },
};
